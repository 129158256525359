.services_menu {
	border-top: 1px dotted #ccc;
}


.services-menu {
 //wrap

	@include media-breakpoint-up(lg) {
		@include make-flex;
		flex-wrap: nowrap;
		align-items: center;
		.service-menu-title {
			margin-bottom: 0;
			padding-right: 15px;
			white-space: nowrap;
		}
	}

}

.service-menu-title {
	margin-top: 0;
}

.service-menu {
	@include make-flex;
}

.service-menu > .menu-item {
	//@include make-col(6);
	padding: 4px;
	&.active > .menu-link {
		border: none !important;
		background-color: transparent !important;
		color: theme(primary, base) !important;
		b { font-weight: $body-weight-bolder; }
	}	
	@include media-breakpoint-down(sm) { @include make-col(6); }
	//@include media-breakpoint-up(lg) { @include make-col(3); }
}

.service-menu .menu-link {
	@include make-flex;
	align-items: center;
	width: 100%;
	padding: 10px 15px;
	border-radius: 8px;
	border: 1px solid #ccc;
	background-color: $white;
	color: $color-text;
	&:hover {
		border-color: theme(primary, base);
		background-color: theme(primary, base);
		color: $white;
	}
	b { width: 100%; }
}