.block_banner {
	padding: 15px;
	text-align: center;
	border-top: 5px solid $color-secondary;
	border-bottom: 5px solid #ddd;
	background-color: $white;

	@include media-breakpoint-up(md) {
		.row { align-item: flex-start; }
		.banner_branding { order: 1; @include make-col(5); }
		.banner_contact { order: 2; @include make-col(7); }
		.banner_slogan { order: 3; }
		.banner_menu { order: 4; }
	}

	@include media-breakpoint-up(lg) {
		padding: 30px 15px;
		.banner_branding { padding: 0;	}
		.banner_contact {
			margin-top: 6px;
			padding: 0;
		}
		.banner_slogan {
			@include make-col(5);
			padding: 0;
		}
		.banner_menu {
			@include make-col(7);
			margin-top: -6px;
			padding: 0;
		}
	}

@include media-breakpoint-up(xl) {
	.row { align-items: center; }
	.banner_branding {
		@include make-col(3);
		order: 1;
		margin-top: 15px;
	}
	.banner_slogan { 
		@include make-col(3);
		order: 2;
		margin-top: 20px;
	}
	.banner_contact {
	 	@include make-col(6);
		order: 3;
		margin-top: 0;
	}	
	.banner_menu {
		@include make-col(6);
		order: 4;
		margin: -17px 0 0 auto;
	}	
}

}

	.banner_button {
		text-align: left;
	}

	.banner_branding {
		@include media-breakpoint-up(md) {
			text-align: left;
			.branding-logo { margin-top: 0; }
		}
	}
		
		.branding-logo {
			margin-top: -40px;
		}

	.banner_slogan {
		@include media-breakpoint-up(lg) {
			text-align: left;
		}
	}

		.branding-slogan {
			padding: 10px 0;
			@include font-size(15);
			font-weight: $body-weight-bold;
			letter-spacing: -0.0375em;
			line-height: 1em;
			color: theme(secondary, dark);
			@include media-breakpoint-only(lg) {
				padding: 0 0 0 55px;
			}
		}

	.banner_contact {
		@include media-breakpoint-up(md) {
			text-align: right;
			@include make-flex;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: flex-end;
		}
		@include media-breakpoint-up(lg) {
			align-self: flex-start;
		}
	}

	.banner_menu {
		margin-top: 15px;
		@include media-breakpoint-up(lg) {
			text-align: right;
		}
	}