.block_main.content {
	background-color: $body-bg;
}

.main {

	@include media-breakpoint-up(lg) {
		.form-title { margin-top: 0; } 
	}


	>*:last-child { margin-bottom: 0; }

}
