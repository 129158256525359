.slider_reviews {

	.review {
        //padding: 0 36px;
    }

    .slick-prev,
    .slick-next {
        display: none !important;
/*         z-index: 999999;
height: 100%;
width: 20px;
background-color: transparent;
background-image: url("/_/images/icons/slide-np-review.png") !important;
background-size: 40px 20px;
opacity: 0.5;
&:hover {
    opacity: 1;
} */
    }	

/*     .slick-prev {
    left: 0;
    background-position: 0 center;
}

.slick-next {
    right: 0;
    background-position: -23px center;
} */

}