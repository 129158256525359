.form_quote {

	.form_group.form_radio label {
		margin-bottom: 10px;
		padding: 5px;
		.radio { margin-top: -3px; }
	}

	.checkbox_group .form_group {
		label { padding: 5px; }
		.checkbox { margin-top: -2px; }
	}

}