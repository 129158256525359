.widget_menu {

	.widget-title {
		padding: 10px 5px 5px;
	}

	.menu-item {
		&.active .menu-link {
			color: theme(primary, base);
			.caret-push { fill: theme(primary, base); }
		}
		&:not(:last-child) { border-bottom: 1px dotted #ddd; }
	}

	.menu-link {
		@include font-size(15);
		padding: 10px;
		font-weight: $body-weight-bold;
		color: $color-text;
		&:hover {
			color: $color-secondary;
			.caret-push { fill: $color-secondary; }
		}
	}

}