// ===========================================
// BOOTSTRAP GRID FUNCTIONS
// ===========================================

@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  	$n: index($breakpoint-names, $name);
  	@return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  	$min: map-get($breakpoints, $name);
  	@return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  	$next: breakpoint-next($name, $breakpoints);
  	@return if($next, breakpoint-min($next, $breakpoints) - 1px, null);
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  	@return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}



// ===========================================
// MATH FUNCTIONS
// ===========================================
@function pow($number, $exponent) {
	$value: 1;
	@if $exponent > 0 {
		@for $i from 1 through $exponent {
			$value: $value * $number;
		}
	}
	@return $value;
}

@function calculateRem($size, $base) {
	$remSize: $size / $base;
	@return $remSize * 1rem;
}

// ===========================================
// COLOR FUNCTIONS
// ===========================================
@function theme($color-name, $color-variant:null) {
	// color variant is optional
	@if ($color-variant != null) {
		// map inception
		@return map-get(map-get($theme, $color-name), $color-variant);
	} @else {
		@return map-get($theme, $color-name);
	}
}