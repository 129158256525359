.hours {
	margin: 0 0 10px;
	line-height: 1em;
}

	.hours-title {
		font-size: inherit;
	}


.contact-hours {}


.company-hours {}
