.branding-title {
	margin: 0;
	font-weight: normal;
	line-height: 0;
}

.logo {
	display: inline-block;
}

	.branding-logo {
		width: 240px;
	}

	.site-info-logo {}