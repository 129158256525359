.slider_jumbo {

    margin: 0 auto;
    line-height: 0px;

/*     &:hover {
    .slick-prev,
    .slick-next { opacity: 0.25; }
} */

    .slick-prev,
    .slick-next {
        opacity: 0;
        //&:hover { opacity: 0.75; }
    }


    .slide-placeholder { width: 100%; }

    .slide { position: relative; }

    .slide_caption {
        padding: 10px 20px;
        @include font-size(16);
        font-weight: $heading-weight-bolder;
        line-height: 1em;
        text-align: center;
        text-shadow: 0 1px 2px rgba(black, 0.5);
        background-color: rgba(#63a530, 0.8);
        color: $white;
    }


    @include media-breakpoint-up(lg) {
        .slide_caption {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            display: inline-block;
            width: auto;
            padding-left: 54px;
            @include font-size(32);
            text-align: left;
            background-color: rgba(#63a530, 0.7);
        }
        .slick-slide:nth-child(even) .slide_caption {
            right: 0;
            padding-left: 20px;
            padding-right: 54px;
            text-align: right;
        }
    }

}