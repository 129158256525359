.front_jumbo {
	padding: 0;
	border-bottom: 5px solid #fff;
}

	.jumbo_marketing {
		padding: 0;
		.container { padding: 0; }
		.row { margin: 0; }
	}

	.slider_jumbo {

		.slide .wrap { padding: 0; }

		@include media-breakpoint-up(sm) {

			.slide {
				background-size: cover;
				background-position: top center;
				.wrap {
					background-size: cover;
					background-position: top center;
				}
			}

			.slide-placeholder { opacity: 0; }

			.slide_1 {
				background-image: url("/_/images/slides/jumbo/1-bg.jpg");
				.wrap { background-image: url("/_/images/slides/jumbo/1-lg.jpg"); }
			}
			.slide_2 {
				background-image: url("/_/images/slides/jumbo/2-bg.jpg");
				.wrap { background-image: url("/_/images/slides/jumbo/2-lg.jpg"); }
			}
			.slide_3 {
				background-image: url("/_/images/slides/jumbo/3-bg.jpg");
				.wrap { background-image: url("/_/images/slides/jumbo/3-lg.jpg"); }
			}
			.slide_4 {
				background-image: url("/_/images/slides/jumbo/4-bg.jpg");
				.wrap { background-image: url("/_/images/slides/jumbo/4-lg.jpg"); }
			}
		}

		@include media-breakpoint-up(lg) {
			.slide .wrap { position: relative; }
		}

	}