a {
	font-style: normal;
	text-decoration: underline;
	color: $color-link;
	&:hover,
	&:focus {
		color: $color-hover;
		text-decoration: none;
	}
	&:focus {
		outline: 1px solid lighten($color-secondary, 25%);
		//box-shadow: 0 0 3px 3px rgba($color-link, 0.5);
	}
	&.mask {
		text-decoration: none;
		color: inherit;
		&:hover { color: inherit; }
	}
}

a.img-link {
	text-decoration: none;
}

