.block.front_environment {
	font-weight: $body-weight-bold;
	text-align: center;
	border-bottom: 5px solid #fff;
	background-color: theme(secondary, base);
	@include gdfancy-bg(#166ead);
	color: $white;
	
	p.lead {
		margin: 0 0 8px;
	}

	ul.list {
		margin: 0 0 8px;
		> li:not(:last-child) { margin: 0 15px 0 0; }
	}

	.btn { border: 2px solid $white; }

	@include media-breakpoint-up(md) {
		ul.list { margin-bottom: 0; }
		.environment_text {
			@include make-col(8);
			padding-right: 0;
			text-align: left;
		}
		.environment_conversion { @include make-col(4); }
	}

		@include media-breakpoint-only(md) {
			p.lead { white-space: nowrap; }
			.environment_conversion { margin-top: 40px; }
		}

	@include media-breakpoint-up(lg) {
		.row { align-items: center; }
		//.environment_text { @include make-col(9); }
		.environment_conversion {
			//@include make-col(3);
			.btn {
				@include font-size(17);
				padding: 15px 20px;
			}
		}
	}

		@include media-breakpoint-only(lg) {
			ul.list { max-width: 600px; }
		}

}


.front_services {
	text-align: center;

	@include media-breakpoint-only(md) {
		.service_wrapper {
			@include make-flex;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;
			h3 {
				min-width: 180px;
				margin: 0;
			}
			> div { text-align: left; }
		}
	}

	@include media-breakpoint-up(md) {
		.row { justify-content: center; }
	}

	@include media-breakpoint-up(lg) {
		.services_item { @include make-col(4); }
	}

}


.services_item {

	padding: 10px;
	line-height: 1.4em;

	h3 {
		margin-bottom: 10px;
		@include font-size(20);
		font-weight: $heading-weight-bolder;
		color: $color-secondary;
		strong { display: block; margin-bottom: 10px; }
	}

}

	.service_wrapper {
		display: block;
		position: relative;
		padding: 25px 45px 25px 15px;
		border-radius: 3px;
		border: 1px solid #ddd;
		background-color: $white;
		> *:first-child { margin-top: 0; }
		> *:last-child { margin-bottom: 0; }

		color: $color-text;
		text-decoration: none;
		
		p { margin-bottom: 0; }

		.side_more {
			@include transition(all 300ms);
		}

		&:hover {
			color: $color-text;
			.side_more {
				@include transition(all 300ms);
				background-color: $color-secondary;
				.icon { fill: $white; }
			}
			//h3 { text-decoration: underline; }
		}

	}

	.side_more {
		@include make-flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0; bottom: 0; right: 0;
		width: 30px;
		border-radius: 0 3px 3px 0;
		background-color: #e5e5e5;
		.icon {
			@include transform(rotate(-90deg));
			fill: $color-text;
		}
	}