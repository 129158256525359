.front_visit {
	@include media-breakpoint-up(md) {
		.row { justify-content: center; }
		.visit_text { @include make-col(4); }
		.visit_media { @include make-col(8); }
	}
	@include media-breakpoint-up(xl) {
	}
}


	.visit-map {
		border: 5px solid $white;
		background-image: url("/_/images/maps/map-sm.webp");
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;		
		@include media-breakpoint-up(lg) {
			background-image: url("/_/images/maps/map-lg.webp");
		}
	}

	.visit_text_wrapper {
	
		margin-top: 20px;

		@include media-breakpoint-up(sm) {
			@include make-flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			> div { width: 48.5%; }
		}

		@include media-breakpoint-up(md) {
			position: relative;
			max-width: 570px;
			margin: -75px auto 0;
			padding: 20px 20px 0;
			background-image: linear-gradient(to bottom, $white 25%, transparent 90%);
			border: 1px solid #ddd;
			border-bottom: none;
    		box-shadow: 0 -3px 6px -3px rgba(0,0,0,0.35);
    		&::before,
    		&::after {
				content: "";
				position: absolute;
				top: 0;
				width: 1px;
				height: 80%;
				background-image: linear-gradient(
					to bottom,
					#ddd 60%,
					transparent
				);
    		}
    		&::before { left: 0; }
    		&::after { right: 0; }
		}

		@include media-breakpoint-up(xl) {
			max-width: 80%;
		}

	}

	.visit-subtitle {
		margin-top: 0;
	}