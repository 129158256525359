$mobile-button-bg: 			transparent !default;
$toggle-bg: 				theme(secondary, base) !default;
$toggle-color: 				$white !default;
$toggle-hover-bg: 			theme(secondary, light) !default;

.btn-mobile {
	width: 48px; height: 41px;
	padding: 10px 11px 9px;
	background-color: $toggle-bg;
	border: none;
	border-radius: 4px;
	color: $toggle-color;

	&:hover, &:focus {
		outline: none;
		background-color: $toggle-hover-bg;
		cursor: pointer;
	}

	b { display: none; }

}

	.button-bars {
		display: inline-block;
		height: 100%;
		width: 26px;
	}

		.icon-bar {
			@include transition(transform ease-in-out 300ms);
			display: block;
			width: 100%;
			height: 3px;
			border-radius: 4px;
			background-color: $toggle-color;
			&:nth-child(2) { margin: 5px 0; }
		}


.canvas-slid {
	.icon-bar {
		&:nth-child(2) { visibility: hidden; }
		&:nth-child(1),
		&:nth-child(3) {
			@include transition(transform ease-in-out 150ms);
		}
		&:nth-child(1) { transform: rotate(45deg); margin-top: 9px; }
		&:nth-child(3) { transform: rotate(-45deg); margin-top: -16px; }
	}
}
