.embed_responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;

	&.embed_responsive_16by9 { padding-bottom: 56.25%; }
	&.embed_responsive_4by3 { padding-bottom: 75%; }

		.embed_responsive_item, embed, iframe, object, video {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
			border: 0;
		}
}