.site_info {

	background-color: $color-secondary;
	@include gdfancy-bg(#166ead);
	color: $white;
	a { color: $white; }

	.phone {
		@include font-size(20);
		font-weight: $body-weight-bolder;
		color: $white;
	}

	.list > li:not(:last-child) { margin-bottom: 4px; }

	@include media-breakpoint-up(sm) {
		.row { align-items: center; }
		.wrap { @include make-col(6); }
		.info_copyright { text-align: right; }
		.list { margin-bottom: 0; }
	}
}
