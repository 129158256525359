.front_welcome {

	@include media-breakpoint-up(sm) {
		//.welcome_text { @include make-col(8);  }
		//.welcome_media { @include make-col(4); }
	}

 	@include media-breakpoint-up(lg) {
		.row { justify-content: center; }
		.wrap { @include make-col(9); }
	}

 	@include media-breakpoint-up(xl) {
 		.welcome-media {
 			margin-top: -70px !important;
 		}
	}

}

	.welcome_title {
		/* text-align: center;
		@include media-breakpoint-up(lg) { text-align: left; } */
	}

	.welcome_text {}

	.welcome-img {
		clear: both;
		float: right;
		margin: 0 10px 20px 20px;
		width: 154px;
		@include media-breakpoint-up(md) {
			padding: 0 20px;
			width: 209px;
		}
	}

	.welcome-media {
		float: right;
		clear: both;
		margin: 0 10px 20px 20px;
	}

		.list-welcome {
			display: inline-block;
			margin-top: 10px;
			padding: 35px 0;
			@include limbo;
			@include font-size(17);
			font-weight: $body-weight-bold;
			line-height: 1em;
			letter-spacing: 0.15em;
			text-align: center;
			background: url("/_/images/layout/home-bg.png") center center no-repeat;
			background-size: contain;
			color: $color-secondary;
			strong {
				@include font-size(21);
				font-weight: $body-weight-bolder;
				letter-spacing: -0.0475em;
				color: $color-primary;
			}
			li:first-child { margin-left: 3em; }
			li:last-child { margin-left: -1em; }
			@include media-breakpoint-up(md) {
				@include font-size(20);
				strong { @include font-size(27); }
			}
		}