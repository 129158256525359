.phone {
	text-decoration: none;
	color: theme(primary, base);
	&:hover {
		cursor: default;
		color: theme(primary, base);
	}
}

	.contact-call {
		line-height: 1em;
	}

	.banner-call {
		margin-bottom: 0;
	}

	.contact-title {
		display: block;
		font-weight: $heading-weight-bold;
		line-height: 1em;
	}
	
	.banner-phone {
		@include font-size(24);
		font-weight: $body-weight-bolder;
		line-height: 1em;
		letter-spacing: -0.0475em;
	}
