@mixin limbo {
	li { margin-bottom: 0 !important; }
}

%list, .list {
	margin: 0 0 1em;
	padding: 0 0 0 1.2em;	
	li:not(:last-child) { margin-bottom: 0.25em; }
}

		@mixin list {
			margin: 0 0 1em;
			padding: 0 0 0 1.2em;	
			li:not(:last-child) { margin-bottom: 0.25em; }		
		}

%list_unstyled, .list-unstyled {
	margin-left: 0;
	padding-left: 0;
	list-style-type: none;
	list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
}
		
		@mixin list_unstyled {
			margin-left: 0;
			padding-left: 0;
			list-style-type: none;
			list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);		
		}

%list_inline, .list-inline {
	@extend %list_unstyled;
	li { display: inline-block; }
}

		@mixin list_inline {
			@include list_unstyled;
			li { display: inline-block; }
		}

.list-wrap {
	@include media-breakpoint-up(md) {
		@include make-flex;
		&.wrap-2 {
			.list {padding: 0 25px;}
		}
	}
}