.youtube-player {
    position: relative;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
    &.yt-16x9 { padding-bottom: 56.23%; }
    &.yt-4x3 { padding-bottom: 75%; }
}

	.youtube-player iframe {
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    z-index: 100;
	    background: transparent;
	}

	.youtube-player img {
	    display: block;
	    position: absolute;
	    z-index: 2000;
	    top: 0; right: 0;
	    bottom: 0; left: 0;
	    width: 100%;
	    max-width: 100%;
	    height: auto;
	    margin: auto;
	    border: none;
	    cursor: pointer;
	    opacity: 0.7;
	    @include transition(all ease-in-out 300ms);

	    &:hover { opacity: 1.0; }

	}

	.youtube-player .play {
	    position: absolute;
	    z-index: 5000;
	    height: 72px;
	    width: 72px;
	    left: 50%;
	    top: 50%;
	    margin-left: -36px;
	    margin-top: -36px;
	    background: url("/_/images/layout/play.png") no-repeat;
	    cursor: pointer;
	    &:hover + img { opacity: 1.0; }
	}
