.review {

	.review-wrap { 
		//border-radius: 5px;
		//border: 2px solid $color-review;
		//background-color: $white;
		padding: 0 15px;
	}

	p {
		margin-bottom: 0;
		line-height: 1.425em;
	}

	cite {
		display: block;
		margin-top: 5px
	}

	.icon-review {
		display: block;
		height: 25px;
		width: 125px;
		margin: 0 auto 10px;
		fill: $color-review;
	}

}