$form_focus_border: 		$yellow;
$form_focus_bg: 			lighten($yellow, 40%);
$placeholder-color: 		rgba($white, 0.9);
$placeholder-hover: 		rgba(black, 0.3);

.form {

	font-family: $font-family-sans-serif;
	line-height: 1em;

}

.form_section {
	overflow: hidden;
	margin: 0 0 20px;
	padding: 15px 15px 7px;
	border: 1px solid #ccc;

	hr {
		margin: 15px 0 20px;
		background-color: #c5c5c5;
	}

	@include media-breakpoint-up(md) {
		
		.form--left,
		.form_city {
			float: left;
			clear: both;
			width: 49%;
		}

		.form--right {
			float: right;
			width: 49%;
		}

		.form_state {
			float: left;
			width: 23.5%;
			margin: 0 2%;
		}

		.form_zip {
			float: right;
			width: 23.5%;
		}

		.form--clear { clear: both; }

		.checkbox_group {
			@include make-flex;
			.form_checkbox {
				width: percentage(1/2);
			}
		}

		.form_radio {
			@include make-flex;
			label { margin-right: 15px; }
		}

	}

}


	.section-title {
		padding: 5px 45px 5px 10px;	
		@include font-size(18);
		font-weight: $body-weight-bolder;
		text-transform: uppercase;
		letter-spacing: -0.0475em;
	}

	.section-subtitle {
		margin: 10px 0;
		@include font-size(16);
		font-weight: $body-weight-bold;
		font-family: $font-family-body;
		text-transform: none;
		letter-spacing: -0.0475em;
		color: $color-text;
	}


	.form-title {
		margin: 10px 0;
		font-weight: $heading-weight-bold;
		small {
			@include font-size(15);
		}
	}


	.form_group {
		position: relative;
		margin: 0 0 5px;
		&.form_hp { display: none; }
		&.form_clear { clear: both; }
	}

		label {
			display: block;
			margin: 10px 0 3px;
			@include font-size(15);
			font-weight: $body-weight-bold;
			line-height: 1em;
		}

		.form_control {
			display: block;
			width: 100%;
			padding: 5px;
			line-height: 1em;
			border: 1px solid #ccc;
			background-color: $white;
			outline: none;
			&:focus {
				border-color: $form_focus_border;
				background-color: $form_focus_bg;
			}	
		}

			textarea.form_control {
				min-height: 100px;
				line-height: 1.275em;
			}

		.form_radio,
		.form_checkbox {
 		   	vertical-align: top;
    		line-height: 1em;

			label {
    			display: block;
				clear: both;
				@include font-size(15);
				font-weight: $body-weight-normal;
    			line-height: 1em;
    			cursor: pointer;
			}

			.checkbox, .radio {
    			display: block;
    			margin: 0 15px 0 0;
    			float: left;
    			width: 20px;
    			height: 20px;
    		}

		}


	.form_error .form_control {
		border-radius: 2px 2px 0 0;
		border-color: $color-danger !important;
	}

		.error-message {
			margin: -5px 0 0;
			padding: 6px 4px 4px;
			font-size: 0.7em;
			font-weight: $body-weight-bolder;
			line-height: 1em;
			text-transform: uppercase;
			background-color: theme(danger, base);
			color: $white;
		}


	.form_spam {
		position: relative;
		height: 68px;
		.g-recaptcha {
			position: absolute;
			top: -5px;
			left: -19px;
			@include transform(scale(0.87));
		}
	}