.page_title {
	border-bottom: 5px solid $white;
	background-color: $color-primary;
	color: $white;
	@include gdfancy-bg($color-primary);
	@include media-breakpoint-up(md) { padding: 90px 15px 15px; }
	@include media-breakpoint-up(lg) { padding-top: 150px; }

}

	.page-title {
		margin: 0;
		small {
			font-size: 55%;
			font-style: italic;
			font-weight: $body-weight-normal;
			letter-spacing: -0.01em;
			line-height: 1em;
			color: #ccc;
		}
	}
