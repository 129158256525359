@-ms-viewport {
  width: device-width; }

html {
  position: relative;
  min-height: 100%; }

body {
  min-height: 100%;
  margin: 0;
  background-color: #f5f5f5;
  color: #343a40; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .wrap {
    padding-right: 0;
    padding-left: 0; }

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  -ms-box-sizing: inherit;
  -o-box-sizing: inherit;
  box-sizing: inherit; }

.wrap {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px; }

.flex_container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 1em;
  box-sizing: border-box; }
  .flex_container [class*="col-"] {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding: 0 15px; }
  .flex_container.gutter-fix [class*="col-"] {
    padding-left: 0 !important; }
  @media (min-width: 576px) {
    .flex_container .col-sm-12 {
      width: 100%; }
    .flex_container .col-sm-11 {
      width: 91.66667%; }
    .flex_container .col-sm-10 {
      width: 83.33333%; }
    .flex_container .col-sm-9 {
      width: 75%; }
    .flex_container .col-sm-8 {
      width: 66.66667%; }
    .flex_container .col-sm-7 {
      width: 58.33333%; }
    .flex_container .col-sm-6 {
      width: 50%; }
    .flex_container .col-sm-5 {
      width: 41.66667%; }
    .flex_container .col-sm-4 {
      width: 33.33333%; }
    .flex_container .col-sm-3 {
      width: 25%; }
    .flex_container .col-sm-2 {
      width: 16.66667%; }
    .flex_container .col-sm-1 {
      width: 8.33333%; } }
  @media (min-width: 768px) {
    .flex_container .col-md-12 {
      width: 100%; }
    .flex_container .col-md-11 {
      width: 91.66667%; }
    .flex_container .col-md-10 {
      width: 83.33333%; }
    .flex_container .col-md-9 {
      width: 75%; }
    .flex_container .col-md-8 {
      width: 66.66667%; }
    .flex_container .col-md-7 {
      width: 58.33333%; }
    .flex_container .col-md-6 {
      width: 50%; }
    .flex_container .col-md-5 {
      width: 41.66667%; }
    .flex_container .col-md-4 {
      width: 33.33333%; }
    .flex_container .col-md-3 {
      width: 25%; }
    .flex_container .col-md-2 {
      width: 16.66667%; }
    .flex_container .col-md-1 {
      width: 8.33333%; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .flex_container .col-md-break {
      width: 100%; } }
  @media (min-width: 992px) {
    .flex_container .col-lg-12 {
      width: 100%; }
    .flex_container .col-lg-11 {
      width: 91.66667%; }
    .flex_container .col-lg-10 {
      width: 83.33333%; }
    .flex_container .col-lg-9 {
      width: 75%; }
    .flex_container .col-lg-8 {
      width: 66.66667%; }
    .flex_container .col-lg-7 {
      width: 58.33333%; }
    .flex_container .col-lg-6 {
      width: 50%; }
    .flex_container .col-lg-5 {
      width: 41.66667%; }
    .flex_container .col-lg-4 {
      width: 33.33333%; }
    .flex_container .col-lg-3 {
      width: 25%; }
    .flex_container .col-lg-2 {
      width: 16.66667%; }
    .flex_container .col-lg-1 {
      width: 8.33333%; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .flex_container .col-lg-break {
      width: 100%; } }
  @media (min-width: 1200px) {
    .flex_container .col-xl-break {
      width: 100%; }
    .flex_container .col-xl-12 {
      width: 100%; }
    .flex_container .col-xl-11 {
      width: 91.66667%; }
    .flex_container .col-xl-10 {
      width: 83.33333%; }
    .flex_container .col-xl-9 {
      width: 75%; }
    .flex_container .col-xl-8 {
      width: 66.66667%; }
    .flex_container .col-xl-7 {
      width: 58.33333%; }
    .flex_container .col-xl-6 {
      width: 50%; }
    .flex_container .col-xl-5 {
      width: 41.66667%; }
    .flex_container .col-xl-4 {
      width: 33.33333%; }
    .flex_container .col-xl-3 {
      width: 25%; }
    .flex_container .col-xl-2 {
      width: 16.66667%; }
    .flex_container .col-xl-1 {
      width: 8.33333%; } }

@media (min-width: 992px) {
  .content .main {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .content .sidebar {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 992px) {
  .content.full_width .main {
    flex: 0 0 100%;
    max-width: 100%; } }

.page_contact {
  /* 		@include media-breakpoint-up(xl) {
	.content_text { @include make-col(4); }
	.content_contact { @include make-col(8); }
} */ }
  @media (min-width: 992px) {
    .page_contact .content_text {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .page_contact .content_contact {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; } }

@media (min-width: 992px) {
  .content.flip .main {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    order: 2; }
  .content.flip .sidebar {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    order: 1; } }

body {
  min-width: 360px;
  background-color: #f5f5f5; }
  @media (min-width: 576px) {
    body:not(.page_front) {
      height: 100%;
      padding-bottom: 124px; }
      body:not(.page_front) .site_info {
        position: absolute;
        bottom: 0;
        width: 100%; } }
  @media (min-width: 768px) {
    body {
      background-image: url("/_/images/layout/full-bloom.png"); } }

#directions,
#hours {
  outline: none !important;
  box-shadow: none !important; }

html {
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: -0.0275em;
  line-height: 1.64706em;
  -webkit-text-size-adjust: 100%; }
  @media (min-width: 992px) {
    html {
      font-size: 17px;
      font-size: 1.0625rem; } }

body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

h5, .h5,
h6, .h6, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin: 30px 0 15px;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: -0.0425em; }
  h5 small, .h5 small,
  h6 small, .h6 small, h4 small, .h4 small, h3 small, .h3 small, h2 small, .h2 small, h1 small, .h1 small {
    display: block;
    font-size: 75%;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    letter-spacing: -0.0125em;
    line-height: 1em;
    color: #888; }

h5, .h5,
h6, .h6 {
  font-size: 16px;
  font-size: 1rem; }
  @media (min-width: 992px) {
    h5, .h5,
    h6, .h6 {
      font-size: 17px;
      font-size: 1.0625rem; } }

h4, .h4 {
  font-size: 18px;
  font-size: 1.125rem; }
  @media (min-width: 992px) {
    h4, .h4 {
      font-size: 20px;
      font-size: 1.25rem; } }

h3, .h3 {
  font-size: 20px;
  font-size: 1.25rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 800;
  letter-spacing: -0.0525em; }
  h3 small, .h3 small {
    font-weight: 600; }
  @media (min-width: 992px) {
    h3, .h3 {
      font-size: 23px;
      font-size: 1.4375rem; } }

h2, .h2 {
  font-size: 22px;
  font-size: 1.375rem; }
  @media (min-width: 992px) {
    h2, .h2 {
      font-size: 27px;
      font-size: 1.6875rem; } }

h1, .h1 {
  font-size: 27px;
  font-size: 1.6875rem;
  font-weight: 800;
  letter-spacing: -0.0525em; }
  h1 small, .h1 small {
    font-weight: 600; }
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 36px;
      font-size: 2.25rem; } }

p {
  margin: 0 0 15px; }
  p.lead {
    font-size: 118%;
    line-height: 1.53em; }

ul, ol {
  margin: 0;
  padding: 0; }

b, strong {
  font-weight: 600; }

address {
  margin: 0 0 15px;
  font-style: normal; }

blockquote {
  margin: 0 0 1.5em;
  padding: 1em;
  font-size: 112.5%;
  line-height: 1.425em;
  border-left: 0.5rem solid; }
  blockquote > * {
    margin-bottom: 0.5em; }
  blockquote > *:last-child {
    margin-bottom: 0; }
  blockquote.bq--text {
    border-color: #343a40; }
  blockquote.bq--primary {
    border-color: #77bb43; }
  blockquote.bq--secondary {
    border-color: #227dc1; }
  blockquote.bq--highlight {
    border-color: #f5841f; }
  blockquote.bq--accent {
    border-color: #cc00ff; }
  blockquote.bq--success {
    border-color: #28a745; }
  blockquote.bq--danger {
    border-color: #dc3545; }
  blockquote.bq--warning {
    border-color: #ba8b00; }
  blockquote.bq--info {
    border-color: #0182C8; }
  blockquote.review {
    padding: 0;
    margin: 0 0 1em;
    font-size: 1rem;
    border: none; }

.bq--title {
  font-weight: 800;
  letter-spacing: -0.0275em;
  text-transform: uppercase; }
  .bq--text .bq--title {
    color: #343a40; }
  .bq--primary .bq--title {
    color: #77bb43; }
  .bq--secondary .bq--title {
    color: #227dc1; }
  .bq--highlight .bq--title {
    color: #f5841f; }
  .bq--accent .bq--title {
    color: #cc00ff; }
  .bq--success .bq--title {
    color: #28a745; }
  .bq--danger .bq--title {
    color: #dc3545; }
  .bq--warning .bq--title {
    color: #ba8b00; }
  .bq--info .bq--title {
    color: #0182C8; }

blockquote.bq-alt {
  margin-right: 5em;
  padding: 1.5em;
  border-radius: 6px;
  border: 4px solid #343a40;
  background-color: #fff; }
  blockquote.bq-alt.bq--primary {
    border-color: #77bb43; }
  blockquote.bq-alt.bq--secondary {
    border-color: #227dc1; }
  blockquote.bq-alt.bq--highlight {
    border-color: #f5841f; }
  blockquote.bq-alt.bq--accent {
    border-color: #cc00ff; }
  blockquote.bq-alt.bq--success {
    border-color: #28a745; }
  blockquote.bq-alt.bq--danger {
    border-color: #dc3545; }
  blockquote.bq-alt.bq--warning {
    border-color: #ffc107; }
  blockquote.bq-alt.bq--info {
    border-color: #0182C8; }

hr {
  display: block;
  width: 100%;
  height: 1px;
  margin: 2em auto;
  border: none;
  background-color: #bbb; }
  hr.hr-inset {
    margin: 2em auto;
    border: none;
    background-color: #bbb;
    box-shadow: 0 1px 0 0 #fff; }
  .content_text hr {
    margin: 1em 0 !important; }

a {
  font-style: normal;
  text-decoration: underline;
  color: #227dc1; }
  a:hover, a:focus {
    color: #3a97dc;
    text-decoration: none; }
  a:focus {
    outline: 1px solid #7bb9e8; }
  a.mask {
    text-decoration: none;
    color: inherit; }
    a.mask:hover {
      color: inherit; }

a.img-link {
  text-decoration: none; }

.list {
  margin: 0 0 1em;
  padding: 0 0 0 1.2em; }
  .list li:not(:last-child) {
    margin-bottom: 0.25em; }

.list-inline, .menu, .list-unstyled {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }

.list-inline li {
  display: inline-block; }

@media (min-width: 768px) {
  .list-wrap {
    display: flex;
    flex-wrap: wrap; }
    .list-wrap.wrap-2 .list {
      padding: 0 25px; } }

.--has-icon {
  vertical-align: middle; }
  .--has-icon > * {
    vertical-align: middle; }

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 0;
  vertical-align: middle; }

.icon-12 {
  width: 12px;
  height: 12px; }

.icon-16 {
  width: 16px;
  height: 16px; }

.icon-18 {
  width: 18px;
  height: 18px; }

.icon-64 {
  width: 64px;
  height: 64px; }

.icon-primary {
  fill: #77bb43; }

.icon-secondary {
  fill: #227dc1; }

.icon-highlight {
  fill: #f5841f; }

.icon-accent {
  fill: #cc00ff; }

.icon-green {
  fill: #42aa14; }

.icon-blue {
  fill: #0c84ea; }

.icon-orange {
  fill: #ff8400; }

.icon-purple {
  fill: #cc00ff; }

.icon-white {
  fill: #fff; }

img {
  display: block;
  max-width: 100%;
  height: auto;
  border-style: none; }

.img-main {
  margin: 0 0 15px;
  max-width: 360px; }

.img-coupon {
  max-width: 640px; }

.img-about {
  width: 150px; }

.img-auto {
  width: auto; }

.img-inline {
  display: inline; }

.img-thumbnail {
  padding: 5px;
  border: 1px solid #ddd;
  background-color: #fff; }

@media (min-width: 768px) {
  .img--right {
    clear: both;
    float: right;
    width: 55%;
    margin: 0 0 15px 20px;
    max-width: none; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .img--right {
    width: 50%; } }

@media (min-width: 768px) {
  .img--left {
    clear: both;
    float: left;
    width: 45%;
    margin: 0 20px 15px 0; } }

.img--wide {
  margin: 0 0 15px;
  width: 100%;
  max-width: none; }

.img--auto {
  max-width: auto; }
  @media (min-width: 576px) {
    .img--auto {
      width: auto;
      height: auto; } }

.img-captioned {
  padding: 8px;
  border: 1px solid #ccc;
  background-color: #fff;
  margin-bottom: 15px; }
  .img-captioned img {
    padding: 0;
    border: none;
    margin-bottom: 0; }
  .img-captioned span {
    display: block;
    max-width: 360px;
    margin: 10px 5px 2px;
    font-size: 14px;
    font-size: 0.875rem;
    font-style: italic;
    line-height: 1.325em; }

div.img-captioned {
  max-width: 360px; }
  div.img-captioned img {
    width: 100%; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
  line-height: 1em;
  background-color: #fff;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .table * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .table th,
  .table td {
    padding: 0.5em 0.75em;
    vertical-align: top;
    border-top: 1px solid #ccc; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ccc; }
  .table tbody + tbody {
    border-top: 2px solid #ccc; }

.table-bordered {
  border: 1px solid #ccc; }
  .table-bordered th, .table-bordered td {
    border: 1px solid #ccc; }
  .table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3f3f3; }

.table-hover tbody tr:hover {
  background-color: #e5e5e5;
  cursor: pointer; }

@media (max-width: 767px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive > .table-bordered {
      border: 0; } }

.embed_responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .embed_responsive.embed_responsive_16by9 {
    padding-bottom: 56.25%; }
  .embed_responsive.embed_responsive_4by3 {
    padding-bottom: 75%; }
  .embed_responsive .embed_responsive_item, .embed_responsive embed, .embed_responsive iframe, .embed_responsive object, .embed_responsive video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0; }

.best-rating {
  display: inline-block;
  padding: 5px 10px;
  line-height: 1em;
  text-decoration: none;
  color: #343a40 !important; }
  .best-rating svg {
    display: inline;
    width: auto;
    height: 20px; }
    .best-rating svg.icon-google {
      width: 20px; }
    .best-rating svg.icon-stars {
      width: 100px;
      fill: #f90; }

.best-title {
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 600; }

.btn {
  display: inline-block;
  padding: 10px 15px;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1em;
  text-decoration: none;
  border-radius: 4px;
  border: 1px solid;
  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95);
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -ms-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms; }
  .btn:hover {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 200ms;
    -moz-transition: all 200ms;
    -ms-transition: all 200ms;
    -o-transition: all 200ms;
    transition: all 200ms;
    cursor: pointer; }
  .btn.btn--sm {
    font-size: 15px;
    font-size: 0.9375rem;
    padding: 8px 12px; }
  .btn.btn--lg {
    font-size: 17px;
    font-size: 1.0625rem;
    padding: 15px 20px; }
  .btn.btn--wide {
    display: block;
    width: 100%; }

.--solid-primary {
  border: 1px solid #659f39;
  background-color: #6ba83c;
  color: #fff; }
  .--solid-primary:hover {
    border-color: #a0d07b;
    background-color: #84c255;
    color: #fff; }

.--solid-secondary {
  border: 1px solid #1c68a0;
  background-color: #1e6fab;
  color: #fff; }
  .--solid-secondary:hover {
    border-color: #4fa2e0;
    background-color: #268bd7;
    color: #fff; }

.--solid-highlight {
  border: 1px solid #e4710a;
  background-color: #f0770b;
  color: #fff; }
  .--solid-highlight:hover {
    border-color: #f8ac68;
    background-color: #f69137;
    color: #fff; }

.--solid-accent {
  border: 1px solid #ad00d9;
  background-color: #b800e6;
  color: #fff; }
  .--solid-accent:hover {
    border-color: #db4dff;
    background-color: #d11aff;
    color: #fff; }

.--solid-review {
  border: 1px solid #d98200;
  background-color: #e68a00;
  color: #fff; }
  .--solid-review:hover {
    border-color: #ffb84d;
    background-color: #ffa31a;
    color: #fff; }

.--solid-success {
  border: 1px solid #218838;
  background-color: #23923d;
  color: #fff; }
  .--solid-success:hover {
    border-color: #48d368;
    background-color: #2dbc4e;
    color: #fff; }

.--solid-danger {
  border: 1px solid #c82333;
  background-color: #d32535;
  color: #fff; }
  .--solid-danger:hover {
    border-color: #e77681;
    background-color: #e04b59;
    color: #fff; }

.--solid-warning {
  border: 1px solid #e0a800;
  background-color: #edb100;
  color: #fff; }
  .--solid-warning:hover {
    border-color: #ffd454;
    background-color: #ffc721;
    color: #fff; }

.--solid-info {
  border: 1px solid #0169a2;
  background-color: #0172af;
  color: #fff; }
  .--solid-info:hover {
    border-color: #18adfe;
    background-color: #0192e1;
    color: #fff; }

.button_close {
  padding: 5px;
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 1em;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #a71d2a;
  background-color: #dc3545;
  background-image: url("/_/images/icons/close.png");
  background-size: 14px 14px;
  background-position: center center;
  background-repeat: no-repeat;
  color: #fff;
  cursor: pointer; }
  .button_close:hover {
    border-color: #dc3545;
    background-color: #e77681; }

.btn-mobile {
  width: 48px;
  height: 41px;
  padding: 10px 11px 9px;
  background-color: #227dc1;
  border: none;
  border-radius: 4px;
  color: #fff; }
  .btn-mobile:hover, .btn-mobile:focus {
    outline: none;
    background-color: #4fa2e0;
    cursor: pointer; }
  .btn-mobile b {
    display: none; }

.button-bars {
  display: inline-block;
  height: 100%;
  width: 26px; }

.icon-bar {
  -webkit-transition: transform ease-in-out 300ms;
  -moz-transition: transform ease-in-out 300ms;
  -ms-transition: transform ease-in-out 300ms;
  -o-transition: transform ease-in-out 300ms;
  transition: transform ease-in-out 300ms;
  display: block;
  width: 100%;
  height: 3px;
  border-radius: 4px;
  background-color: #fff; }
  .icon-bar:nth-child(2) {
    margin: 5px 0; }

.canvas-slid .icon-bar:nth-child(2) {
  visibility: hidden; }

.canvas-slid .icon-bar:nth-child(1), .canvas-slid .icon-bar:nth-child(3) {
  -webkit-transition: transform ease-in-out 150ms;
  -moz-transition: transform ease-in-out 150ms;
  -ms-transition: transform ease-in-out 150ms;
  -o-transition: transform ease-in-out 150ms;
  transition: transform ease-in-out 150ms; }

.canvas-slid .icon-bar:nth-child(1) {
  transform: rotate(45deg);
  margin-top: 9px; }

.canvas-slid .icon-bar:nth-child(3) {
  transform: rotate(-45deg);
  margin-top: -16px; }

.gmb-button-review {
  display: flex;
  box-sizing: border-box;
  flex-wrap: 1;
  width: 240px;
  height: 61px;
  padding: 0 8px;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1em;
  text-decoration: none;
  border-radius: 6px;
  border: 0.075em solid #ccc;
  background-image: linear-gradient(to right, #fff 50%, #4285f4 50%);
  color: #fff !important; }
  .gmb-button-review svg {
    align-self: center; }
  .gmb-button-review div {
    display: flex;
    align-items: center;
    height: 59px;
    margin-left: 8px;
    padding: 10px;
    text-align: left;
    background-color: #4285F4; }

.review-icon.icon-g {
  width: 40px;
  height: 41px; }

.review-icon.icon-bbb {
  width: 240px;
  height: 61px; }

.btn-social.--facebook {
  border: 1px solid #385796;
  background-color: #3b5c9f;
  color: #fff; }
  .btn-social.--facebook:hover {
    border-color: #7591cc;
    background-color: #5074be;
    color: #fff; }

.phone {
  text-decoration: none;
  color: #77bb43; }
  .phone:hover {
    cursor: default;
    color: #77bb43; }

.contact-call {
  line-height: 1em; }

.banner-call {
  margin-bottom: 0; }

.contact-title {
  display: block;
  font-weight: 600;
  line-height: 1em; }

.banner-phone {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1em;
  letter-spacing: -0.0475em; }

.coverage {
  line-height: 1.275em; }
  .coverage > *:last-child {
    margin-bottom: 0; }
  .coverage p {
    margin-bottom: 8px; }

.coverage_title {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 800; }

.coverage_county {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 600; }

.coverage_city {
  font-size: 16px;
  font-size: 1rem; }

.form {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1em; }

.form_section {
  overflow: hidden;
  margin: 0 0 20px;
  padding: 15px 15px 7px;
  border: 1px solid #ccc; }
  .form_section hr {
    margin: 15px 0 20px;
    background-color: #c5c5c5; }
  @media (min-width: 768px) {
    .form_section .form--left,
    .form_section .form_city {
      float: left;
      clear: both;
      width: 49%; }
    .form_section .form--right {
      float: right;
      width: 49%; }
    .form_section .form_state {
      float: left;
      width: 23.5%;
      margin: 0 2%; }
    .form_section .form_zip {
      float: right;
      width: 23.5%; }
    .form_section .form--clear {
      clear: both; }
    .form_section .checkbox_group {
      display: flex;
      flex-wrap: wrap; }
      .form_section .checkbox_group .form_checkbox {
        width: 50%; }
    .form_section .form_radio {
      display: flex;
      flex-wrap: wrap; }
      .form_section .form_radio label {
        margin-right: 15px; } }

.section-title {
  padding: 5px 45px 5px 10px;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: -0.0475em; }

.section-subtitle {
  margin: 10px 0;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: none;
  letter-spacing: -0.0475em;
  color: #343a40; }

.form-title {
  margin: 10px 0;
  font-weight: 600; }
  .form-title small {
    font-size: 15px;
    font-size: 0.9375rem; }

.form_group {
  position: relative;
  margin: 0 0 5px; }
  .form_group.form_hp {
    display: none; }
  .form_group.form_clear {
    clear: both; }

label {
  display: block;
  margin: 10px 0 3px;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 1em; }

.form_control {
  display: block;
  width: 100%;
  padding: 5px;
  line-height: 1em;
  border: 1px solid #ccc;
  background-color: #fff;
  outline: none; }
  .form_control:focus {
    border-color: #ffc107;
    background-color: #fff4d3; }

textarea.form_control {
  min-height: 100px;
  line-height: 1.275em; }

.form_radio,
.form_checkbox {
  vertical-align: top;
  line-height: 1em; }
  .form_radio label,
  .form_checkbox label {
    display: block;
    clear: both;
    font-size: 15px;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1em;
    cursor: pointer; }
  .form_radio .checkbox, .form_radio .radio,
  .form_checkbox .checkbox,
  .form_checkbox .radio {
    display: block;
    margin: 0 15px 0 0;
    float: left;
    width: 20px;
    height: 20px; }

.form_error .form_control {
  border-radius: 2px 2px 0 0;
  border-color: #dc3545 !important; }

.error-message {
  margin: -5px 0 0;
  padding: 6px 4px 4px;
  font-size: 0.7em;
  font-weight: 800;
  line-height: 1em;
  text-transform: uppercase;
  background-color: #dc3545;
  color: #fff; }

.form_spam {
  position: relative;
  height: 68px; }
  .form_spam .g-recaptcha {
    position: absolute;
    top: -5px;
    left: -19px;
    -webkit-transform: scale(0.87);
    -moz-transform: scale(0.87);
    -o-transform: scale(0.87);
    transform: scale(0.87); }

@media (min-width: 1200px) {
  .sidebar .form_quick .form_spam {
    height: 61px; }
    .sidebar .form_quick .form_spam .g-recaptcha {
      top: -8px;
      left: -32px;
      -webkit-transform: scale(0.79);
      -moz-transform: scale(0.79);
      -o-transform: scale(0.79);
      transform: scale(0.79); } }

.form_quote .form_group.form_radio label {
  margin-bottom: 10px;
  padding: 5px; }
  .form_quote .form_group.form_radio label .radio {
    margin-top: -3px; }

.form_quote .checkbox_group .form_group label {
  padding: 5px; }

.form_quote .checkbox_group .form_group .checkbox {
  margin-top: -2px; }

.gallery {
  overflow: hidden;
  margin-bottom: 1em; }

.gallery_sidebar img {
  margin-bottom: 0.5em; }

.gallery_lightbox > a {
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  display: block;
  overflow: hidden;
  margin-bottom: 1%;
  border: 4px solid transparent; }
  .gallery_lightbox > a img {
    width: 100%;
    -webkit-transition: transform ease-in-out 200ms;
    -moz-transition: transform ease-in-out 200ms;
    -ms-transition: transform ease-in-out 200ms;
    -o-transition: transform ease-in-out 200ms;
    transition: transform ease-in-out 200ms; }
  .gallery_lightbox > a:hover {
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms;
    border-color: #fff; }
    .gallery_lightbox > a:hover img {
      -webkit-transition: transform ease-in-out 200ms;
      -moz-transition: transform ease-in-out 200ms;
      -ms-transition: transform ease-in-out 200ms;
      -o-transition: transform ease-in-out 200ms;
      transition: transform ease-in-out 200ms;
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2); }

@media (min-width: 768px) {
  .gallery--right {
    float: right;
    width: 240px;
    margin: 0 0 15px 15px; } }

@media (max-width: 575px) {
  .g2-xs > img, .g2-xs > a, .g2-xs > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-xs > img:nth-child(odd), .g2-xs > a:nth-child(odd), .g2-xs > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-xs > img:nth-child(even), .g2-xs > a:nth-child(even), .g2-xs > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 576px) and (max-width: 767px) {
  .g2-sm > img, .g2-sm > a, .g2-sm > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-sm > img:nth-child(odd), .g2-sm > a:nth-child(odd), .g2-sm > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-sm > img:nth-child(even), .g2-sm > a:nth-child(even), .g2-sm > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 768px) and (max-width: 991px) {
  .g2-md > img, .g2-md > a, .g2-md > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-md > img:nth-child(odd), .g2-md > a:nth-child(odd), .g2-md > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-md > img:nth-child(even), .g2-md > a:nth-child(even), .g2-md > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .g2-lg > img, .g2-lg > a, .g2-lg > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-lg > img:nth-child(odd), .g2-lg > a:nth-child(odd), .g2-lg > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-lg > img:nth-child(even), .g2-lg > a:nth-child(even), .g2-lg > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 1200px) {
  .g2-xl > img, .g2-xl > a, .g2-xl > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-xl > img:nth-child(odd), .g2-xl > a:nth-child(odd), .g2-xl > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-xl > img:nth-child(even), .g2-xl > a:nth-child(even), .g2-xl > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 576px) and (max-width: 767px) {
  .g3-sm > img, .g3-sm > a, .g3-sm > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-sm > img:nth-child(3n-2), .g3-sm > a:nth-child(3n-2), .g3-sm > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-sm > img:nth-child(3n), .g3-sm > a:nth-child(3n), .g3-sm > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 768px) and (max-width: 991px) {
  .g3-md > img, .g3-md > a, .g3-md > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-md > img:nth-child(3n-2), .g3-md > a:nth-child(3n-2), .g3-md > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-md > img:nth-child(3n), .g3-md > a:nth-child(3n), .g3-md > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .g3-lg > img, .g3-lg > a, .g3-lg > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-lg > img:nth-child(3n-2), .g3-lg > a:nth-child(3n-2), .g3-lg > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-lg > img:nth-child(3n), .g3-lg > a:nth-child(3n), .g3-lg > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 1200px) {
  .g3-xl > img, .g3-xl > a, .g3-xl > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-xl > img:nth-child(3n-2), .g3-xl > a:nth-child(3n-2), .g3-xl > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-xl > img:nth-child(3n), .g3-xl > a:nth-child(3n), .g3-xl > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 576px) and (max-width: 767px) {
  .g4-sm > img, .g4-sm > a, .g4-sm > .img-captioned {
    display: block;
    float: left;
    width: 24.25%;
    margin-right: 1%; }
    .g4-sm > img:nth-child(4n-3), .g4-sm > a:nth-child(4n-3), .g4-sm > .img-captioned:nth-child(4n-3) {
      clear: both; }
    .g4-sm > img:nth-child(4n), .g4-sm > a:nth-child(4n), .g4-sm > .img-captioned:nth-child(4n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 768px) and (max-width: 991px) {
  .g4-md > img, .g4-md > a, .g4-md > .img-captioned {
    display: block;
    float: left;
    width: 24.25%;
    margin-right: 1%; }
    .g4-md > img:nth-child(4n-3), .g4-md > a:nth-child(4n-3), .g4-md > .img-captioned:nth-child(4n-3) {
      clear: both; }
    .g4-md > img:nth-child(4n), .g4-md > a:nth-child(4n), .g4-md > .img-captioned:nth-child(4n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .g4-lg > img, .g4-lg > a, .g4-lg > .img-captioned {
    display: block;
    float: left;
    width: 24.25%;
    margin-right: 1%; }
    .g4-lg > img:nth-child(4n-3), .g4-lg > a:nth-child(4n-3), .g4-lg > .img-captioned:nth-child(4n-3) {
      clear: both; }
    .g4-lg > img:nth-child(4n), .g4-lg > a:nth-child(4n), .g4-lg > .img-captioned:nth-child(4n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 1200px) {
  .g4-xl > img, .g4-xl > a, .g4-xl > .img-captioned {
    display: block;
    float: left;
    width: 24.25%;
    margin-right: 1%; }
    .g4-xl > img:nth-child(4n-3), .g4-xl > a:nth-child(4n-3), .g4-xl > .img-captioned:nth-child(4n-3) {
      clear: both; }
    .g4-xl > img:nth-child(4n), .g4-xl > a:nth-child(4n), .g4-xl > .img-captioned:nth-child(4n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 1200px) {
  .g5-xl > img, .g5-xl > a, .g5-xl > .img-captioned {
    display: block;
    float: left;
    width: 19.2%;
    margin-right: 1%; }
    .g5-xl > img:nth-child(5n-4), .g5-xl > a:nth-child(5n-4), .g5-xl > .img-captioned:nth-child(5n-4) {
      clear: both; }
    .g5-xl > img:nth-child(5n), .g5-xl > a:nth-child(5n), .g5-xl > .img-captioned:nth-child(5n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 768px) {
  .g6-md > img, .g6-md > a, .g6-md > .img-captioned {
    display: block;
    float: left;
    width: 15.83333%;
    margin-right: 1%; }
    .g6-md > img:nth-child(6n-5), .g6-md > a:nth-child(6n-5), .g6-md > .img-captioned:nth-child(6n-5) {
      clear: both; }
    .g6-md > img:nth-child(6n), .g6-md > a:nth-child(6n), .g6-md > .img-captioned:nth-child(6n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 992px) {
  .g6-lg > img, .g6-lg > a, .g6-lg > .img-captioned {
    display: block;
    float: left;
    width: 15.83333%;
    margin-right: 1%; }
    .g6-lg > img:nth-child(6n-5), .g6-lg > a:nth-child(6n-5), .g6-lg > .img-captioned:nth-child(6n-5) {
      clear: both; }
    .g6-lg > img:nth-child(6n), .g6-lg > a:nth-child(6n), .g6-lg > .img-captioned:nth-child(6n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 1200px) {
  .g6-xl > img, .g6-xl > a, .g6-xl > .img-captioned {
    display: block;
    float: left;
    width: 15.83333%;
    margin-right: 1%; }
    .g6-xl > img:nth-child(6n-5), .g6-xl > a:nth-child(6n-5), .g6-xl > .img-captioned:nth-child(6n-5) {
      clear: both; }
    .g6-xl > img:nth-child(6n), .g6-xl > a:nth-child(6n), .g6-xl > .img-captioned:nth-child(6n) {
      float: right;
      margin-right: 0; } }

.hours {
  margin: 0 0 10px;
  line-height: 1em; }

.hours-title {
  font-size: inherit; }

.license {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1em; }

.license-title {
  font-size: inherit; }

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99000;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none; }

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 99500;
  text-align: center;
  line-height: 0;
  font-weight: 400; }
  @media (min-width: 768px) {
    .lightbox {
      margin-top: 75px; } }

.lightbox .lb-image {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto; }

.lightbox a img {
  border: none; }

.lb-outerContainer {
  position: relative;
  padding: 3px;
  overflow: hidden;
  background-color: white;
  *zoom: 1;
  margin: 0 auto; }

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0; }

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url("/_/images/layout/loading.gif") no-repeat; }

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10; }

.lb-container > .nav {
  left: 0; }

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="); }

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block; }

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url("/_/images/icons/lb_prev.png") left 48% no-repeat;
  background-size: 28px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url("/_/images/icons/lb_next.png") right 48% no-repeat;
  background-size: 28px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-dataContainer {
  margin: 0 auto;
  padding: 7px 0;
  *zoom: 1;
  width: 100%;
  border-top: 3px solid #fff;
  background-color: #fff;
  background-color: transparent;
  color: #343a40; }

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-data {
  padding: 0 4px; }

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em; }

.lb-data .lb-caption {
  display: inline-block;
  padding: 6px 5px 0;
  font-size: 1rem;
  font-weight: 800;
  color: #fff;
  text-transform: uppercase;
  line-height: 1em; }

.lb-data .lb-number {
  display: none !important;
  visibility: hidden !important; }

.lb-data .lb-close {
  display: block;
  float: right;
  margin-right: 5px;
  width: 30px;
  height: 30px;
  background: url("/_/images/icons/close.png") center center no-repeat;
  background-size: 20px 20px;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s; }

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.branding-title {
  margin: 0;
  font-weight: normal;
  line-height: 0; }

.logo {
  display: inline-block; }

.branding-logo {
  width: 240px; }

.g_map {
  position: relative;
  background-image: url("/_/images/layout/gmap.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  .g_map:hover {
    cursor: pointer; }
    .g_map:hover p {
      opacity: 1; }
  .g_map p {
    position: absolute;
    top: 45%;
    width: 100%;
    padding: 0.5em;
    font-size: 20px;
    font-size: 1.25rem;
    /* font-family: $font-family-headings;
		font-weight: $font-weight-bold; */
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
    background-color: rgba(34, 125, 193, 0.8);
    color: #fff;
    opacity: 0; }

.menu {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.menu-item {
  box-sizing: border-box;
  list-style-type: none; }
  .menu-item.active .menu-link {
    cursor: default; }
    .menu-item.active .menu-link:hover {
      cursor: default; }
    .menu-item.active .menu-link .caret-push {
      width: 10px;
      margin-right: 3px; }
  .menu-item.open .dropdown-menu {
    display: block !important;
    height: auto; }
  .menu-item.open .caret {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }

.menu-link {
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  display: block;
  padding: 12px 15px;
  line-height: 1em;
  text-decoration: none; }
  .menu-link:hover, .menu-link:focus {
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms;
    outline: none; }
    .menu-link:hover .caret-push, .menu-link:focus .caret-push {
      -webkit-transition: all ease-in-out 300ms;
      -moz-transition: all ease-in-out 300ms;
      -ms-transition: all ease-in-out 300ms;
      -o-transition: all ease-in-out 300ms;
      transition: all ease-in-out 300ms;
      width: 10px;
      margin-right: 3px; }

.caret,
.caret-push {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  top: 3px;
  vertical-align: top;
  fill: #343a40; }

.caret-push {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  margin-right: 0;
  width: 0; }

.dropdown-menu {
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  display: none;
  position: relative;
  z-index: 95000;
  margin: 0;
  padding: 1em;
  min-width: 280px; }
  .dropdown-menu .menu-item {
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
    text-align: left; }
  @media (min-width: 768px) {
    .dropdown-menu {
      position: absolute; } }

.menu_main {
  line-height: 0; }

.menu-main > .menu-item {
  z-index: 7;
  position: relative;
  display: inline-block;
  padding: 0 4px;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 600; }
  .menu-main > .menu-item.active > .menu-link {
    border: none !important;
    background-color: transparent !important;
    color: #77bb43 !important; }
    .menu-main > .menu-item.active > .menu-link b {
      font-weight: 800; }
  .menu-main > .menu-item.open > .menu-link {
    border-color: #ddd !important;
    background-color: #fff !important;
    color: #77bb43; }
    .menu-main > .menu-item.open > .menu-link .caret {
      fill: #77bb43; }
  .menu-main > .menu-item > .menu-link {
    padding: 10px 15px;
    border-radius: 3px;
    border: 1px solid transparent;
    background-color: #227dc1;
    color: #fff; }
    .menu-main > .menu-item > .menu-link .caret {
      fill: #fff; }
    .menu-main > .menu-item > .menu-link:hover {
      background-color: #77bb43; }

.menu-main .menu-link {
  padding: 10px;
  color: #343a40; }

.menu-main .dropdown-menu {
  z-index: 0;
  margin-top: 5px;
  border-radius: 3px;
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0 15px 10px -15px rgba(0, 0, 0, 0.6); }
  .menu-main .dropdown-menu .menu-item.active .menu-link {
    color: #77bb43; }
    .menu-main .dropdown-menu .menu-item.active .menu-link .caret-push {
      fill: #77bb43; }
  .menu-main .dropdown-menu .menu-item:not(:last-child) {
    border-bottom: 1px dotted #bbb; }
  .menu-main .dropdown-menu .menu-link:hover {
    color: #227dc1; }
    .menu-main .dropdown-menu .menu-link:hover .caret-push {
      fill: #227dc1; }

body.service_page .menu-main > .item_page_services.open > .menu-link {
  border-bottom: none; }

body.service_page .menu-main > .item_page_services > .menu-link {
  border-radius: 0;
  border-bottom: 1px solid #77bb43;
  color: #77bb43; }
  body.service_page .menu-main > .item_page_services > .menu-link .caret {
    fill: #77bb43; }
  body.service_page .menu-main > .item_page_services > .menu-link:hover {
    border-radius: 3px; }

.nav_mobile {
  display: none;
  position: fixed;
  z-index: 999999;
  top: 0;
  right: auto;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  width: 90%;
  max-width: 300px;
  height: 100%;
  padding: 30px 15px;
  border-right: 3px solid #fff;
  box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #227dc1;
  color: #fff; }
  .nav_mobile.in {
    display: block; }
  @media (min-width: 768px) {
    .nav_mobile {
      display: none !important; } }
  .nav_mobile h4 {
    font-size: 1.2em;
    font-weight: 800;
    text-transform: uppercase; }
  .nav_mobile .menu > .menu-item:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.9); }
  .nav_mobile .menu > .menu-item:not(:first-child) {
    border-top: 1px solid rgba(255, 255, 255, 0.2); }
  .nav_mobile .menu > .menu-item.active .menu-link {
    background-color: #fff;
    color: #77bb43; }
    .nav_mobile .menu > .menu-item.active .menu-link:hover, .nav_mobile .menu > .menu-item.active .menu-link:focus {
      outline: none;
      background-color: #fff;
      color: #77bb43; }
  .nav_mobile .menu > .menu-item.open > .menu-link {
    background-color: #cc00ff; }
  .nav_mobile .menu > .menu-item.open .dropdown-menu {
    display: block; }
  .nav_mobile .menu > .menu-item > .menu-link {
    padding: 12px;
    font-weight: 600;
    background-color: transparent;
    color: #fff; }
    .nav_mobile .menu > .menu-item > .menu-link:hover, .nav_mobile .menu > .menu-item > .menu-link:focus {
      outline: none;
      background-color: #77bb43;
      color: #fff; }
  .nav_mobile .dropdown-menu {
    display: none;
    padding: 0;
    min-width: 0;
    background-color: rgba(255, 255, 255, 0.8); }
    .nav_mobile .dropdown-menu .menu-item:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.3); }
    .nav_mobile .dropdown-menu .menu-item:not(:first-child) {
      border-top: 1px solid white; }
    .nav_mobile .dropdown-menu .menu-link {
      padding: 12px;
      font-weight: 600;
      background-color: transparent;
      color: #227dc1; }
      .nav_mobile .dropdown-menu .menu-link:hover, .nav_mobile .dropdown-menu .menu-link:focus {
        outline: none;
        color: #cc00ff; }
  .nav_mobile .mobile-toggle {
    font-size: 1.2em;
    color: #fff;
    text-decoration: none; }
    .nav_mobile .mobile-toggle img {
      display: inline-block;
      width: 0.8em;
      margin-right: 10px; }

.services_menu {
  border-top: 1px dotted #ccc; }

@media (min-width: 992px) {
  .services-menu {
    display: flex;
    flex-wrap: wrap;
    flex-wrap: nowrap;
    align-items: center; }
    .services-menu .service-menu-title {
      margin-bottom: 0;
      padding-right: 15px;
      white-space: nowrap; } }

.service-menu-title {
  margin-top: 0; }

.service-menu {
  display: flex;
  flex-wrap: wrap; }

.service-menu > .menu-item {
  padding: 4px; }
  .service-menu > .menu-item.active > .menu-link {
    border: none !important;
    background-color: transparent !important;
    color: #77bb43 !important; }
    .service-menu > .menu-item.active > .menu-link b {
      font-weight: 800; }
  @media (max-width: 767px) {
    .service-menu > .menu-item {
      flex: 0 0 50%;
      max-width: 50%; } }

.service-menu .menu-link {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #343a40; }
  .service-menu .menu-link:hover {
    border-color: #77bb43;
    background-color: #77bb43;
    color: #fff; }
  .service-menu .menu-link b {
    width: 100%; }

.review .review-wrap {
  padding: 0 15px; }

.review p {
  margin-bottom: 0;
  line-height: 1.425em; }

.review cite {
  display: block;
  margin-top: 5px; }

.review .icon-review {
  display: block;
  height: 25px;
  width: 125px;
  margin: 0 auto 10px;
  fill: #f90; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

.slick-slide img {
  display: block;
  margin: 0; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("/_/images/layout/ajax-loader.gif") center center no-repeat; }

/* Arrows */
.slick-prev,
.slick-next {
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  z-index: 95000;
  position: absolute;
  display: block;
  width: 44px;
  height: 44px;
  font-size: 0px;
  line-height: 0px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.4);
  background-size: 30%;
  background-position: center center;
  background-repeat: no-repeat;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  opacity: 0.5; }
  .slick-prev:hover,
  .slick-next:hover {
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    outline: none; }

.slick-prev {
  left: 10px;
  background-image: url("/_/images/icons/angle_left.png"); }

.slick-next {
  right: 10px;
  background-image: url("/_/images/icons/angle_right.png"); }

.slider_jumbo {
  margin: 0 auto;
  line-height: 0px;
  /*     &:hover {
    .slick-prev,
    .slick-next { opacity: 0.25; }
} */ }
  .slider_jumbo .slick-prev,
  .slider_jumbo .slick-next {
    opacity: 0; }
  .slider_jumbo .slide-placeholder {
    width: 100%; }
  .slider_jumbo .slide {
    position: relative; }
  .slider_jumbo .slide_caption {
    padding: 10px 20px;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 800;
    line-height: 1em;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    background-color: rgba(99, 165, 48, 0.8);
    color: #fff; }
  @media (min-width: 992px) {
    .slider_jumbo .slide_caption {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: inline-block;
      width: auto;
      padding-left: 54px;
      font-size: 32px;
      font-size: 2rem;
      text-align: left;
      background-color: rgba(99, 165, 48, 0.7); }
    .slider_jumbo .slick-slide:nth-child(even) .slide_caption {
      right: 0;
      padding-left: 20px;
      padding-right: 54px;
      text-align: right; } }

.slider_reviews {
  /*     .slick-prev {
    left: 0;
    background-position: 0 center;
}

.slick-next {
    right: 0;
    background-position: -23px center;
} */ }
  .slider_reviews .slick-prev,
  .slider_reviews .slick-next {
    display: none !important;
    /*         z-index: 999999;
height: 100%;
width: 20px;
background-color: transparent;
background-image: url("/_/images/icons/slide-np-review.png") !important;
background-size: 40px 20px;
opacity: 0.5;
&:hover {
    opacity: 1;
} */ }

.youtube-player {
  position: relative;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000; }
  .youtube-player.yt-16x9 {
    padding-bottom: 56.23%; }
  .youtube-player.yt-4x3 {
    padding-bottom: 75%; }

.youtube-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: transparent; }

.youtube-player img {
  display: block;
  position: absolute;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: auto;
  border: none;
  cursor: pointer;
  opacity: 0.7;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .youtube-player img:hover {
    opacity: 1.0; }

.youtube-player .play {
  position: absolute;
  z-index: 5000;
  height: 72px;
  width: 72px;
  left: 50%;
  top: 50%;
  margin-left: -36px;
  margin-top: -36px;
  background: url("/_/images/layout/play.png") no-repeat;
  cursor: pointer; }
  .youtube-player .play:hover + img {
    opacity: 1.0; }

.widget {
  margin-bottom: 30px;
  padding: 15px;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.275em;
  border-radius: 8px;
  border: 2px solid #ccc;
  background-color: #fff; }
  .widget.widget-primary {
    border-color: #77bb43; }
    .widget.widget-primary .widget-title {
      color: #77bb43; }
    .widget.widget-primary a:not(.menu-link):not(.btn) {
      color: #77bb43 !important; }
  .widget.widget-secondary {
    border-color: #227dc1; }
    .widget.widget-secondary .widget-title {
      color: #227dc1; }
    .widget.widget-secondary a:not(.menu-link):not(.btn) {
      color: #227dc1 !important; }
  .widget.widget-highlight {
    border-color: #f5841f; }
    .widget.widget-highlight .widget-title {
      color: #f5841f; }
    .widget.widget-highlight a:not(.menu-link):not(.btn) {
      color: #f5841f !important; }
  .widget.widget-accent {
    border-color: #cc00ff; }
    .widget.widget-accent .widget-title {
      color: #cc00ff; }
    .widget.widget-accent a:not(.menu-link):not(.btn) {
      color: #cc00ff !important; }
  .widget > *:first-child {
    margin-top: 0; }
  .widget > *:last-child {
    margin-bottom: 0; }

.widget-title {
  margin: 15px 5px 10px;
  font-weight: 800;
  letter-spacing: -0.0375em; }

.widget_menu .widget-title {
  padding: 10px 5px 5px; }

.widget_menu .menu-item.active .menu-link {
  color: #77bb43; }
  .widget_menu .menu-item.active .menu-link .caret-push {
    fill: #77bb43; }

.widget_menu .menu-item:not(:last-child) {
  border-bottom: 1px dotted #ddd; }

.widget_menu .menu-link {
  font-size: 15px;
  font-size: 0.9375rem;
  padding: 10px;
  font-weight: 600;
  color: #343a40; }
  .widget_menu .menu-link:hover {
    color: #227dc1; }
    .widget_menu .menu-link:hover .caret-push {
      fill: #227dc1; }

.widget_review {
  border: 2px solid #f90;
  box-shadow: none; }
  .widget_review .review cite {
    vertical-align: middle; }
    .widget_review .review cite img {
      display: block;
      margin: 0 auto 10px;
      vertical-align: middle; }
  @media (min-width: 768px) and (max-width: 991px) {
    .widget_review .review cite img {
      display: inline-block;
      margin: 0 7px 0 0; } }

.block {
  padding: 30px 15px; }
  .block .wrap > *:first-child {
    margin-top: 0; }
  .block .wrap > *:last-child {
    margin-bottom: 0; }

.block_title {
  margin-bottom: 15px; }

.block-title {
  margin: 0; }

.block_banner {
  padding: 15px;
  text-align: center;
  border-top: 5px solid #227dc1;
  border-bottom: 5px solid #ddd;
  background-color: #fff; }
  @media (min-width: 768px) {
    .block_banner .row {
      align-item: flex-start; }
    .block_banner .banner_branding {
      order: 1;
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .block_banner .banner_contact {
      order: 2;
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .block_banner .banner_slogan {
      order: 3; }
    .block_banner .banner_menu {
      order: 4; } }
  @media (min-width: 992px) {
    .block_banner {
      padding: 30px 15px; }
      .block_banner .banner_branding {
        padding: 0; }
      .block_banner .banner_contact {
        margin-top: 6px;
        padding: 0; }
      .block_banner .banner_slogan {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
        padding: 0; }
      .block_banner .banner_menu {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
        margin-top: -6px;
        padding: 0; } }
  @media (min-width: 1200px) {
    .block_banner .row {
      align-items: center; }
    .block_banner .banner_branding {
      flex: 0 0 25%;
      max-width: 25%;
      order: 1;
      margin-top: 15px; }
    .block_banner .banner_slogan {
      flex: 0 0 25%;
      max-width: 25%;
      order: 2;
      margin-top: 20px; }
    .block_banner .banner_contact {
      flex: 0 0 50%;
      max-width: 50%;
      order: 3;
      margin-top: 0; }
    .block_banner .banner_menu {
      flex: 0 0 50%;
      max-width: 50%;
      order: 4;
      margin: -17px 0 0 auto; } }

.banner_button {
  text-align: left; }

@media (min-width: 768px) {
  .banner_branding {
    text-align: left; }
    .banner_branding .branding-logo {
      margin-top: 0; } }

.branding-logo {
  margin-top: -40px; }

@media (min-width: 992px) {
  .banner_slogan {
    text-align: left; } }

.branding-slogan {
  padding: 10px 0;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 600;
  letter-spacing: -0.0375em;
  line-height: 1em;
  color: #175380; }
  @media (min-width: 992px) and (max-width: 1199px) {
    .branding-slogan {
      padding: 0 0 0 55px; } }

@media (min-width: 768px) {
  .banner_contact {
    text-align: right;
    display: flex;
    flex-wrap: wrap;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end; } }

@media (min-width: 992px) {
  .banner_contact {
    align-self: flex-start; } }

.banner_menu {
  margin-top: 15px; }
  @media (min-width: 992px) {
    .banner_menu {
      text-align: right; } }

.page_title {
  border-bottom: 5px solid #fff;
  background-color: #77bb43;
  color: #fff;
  background: #77bb43;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #77bb43; }
  @media (min-width: 768px) {
    .page_title {
      padding: 90px 15px 15px; } }
  @media (min-width: 992px) {
    .page_title {
      padding-top: 150px; } }

.page-title {
  margin: 0; }
  .page-title small {
    font-size: 55%;
    font-style: italic;
    font-weight: 400;
    letter-spacing: -0.01em;
    line-height: 1em;
    color: #ccc; }

.block_main.content {
  background-color: #f5f5f5; }

@media (min-width: 992px) {
  .main .form-title {
    margin-top: 0; } }

.main > *:last-child {
  margin-bottom: 0; }

.sidebar .form {
  margin-bottom: 30px;
  padding: 10px 15px 15px;
  border: 1px solid #ddd;
  background-color: #fff; }

.sidebar > *:last-child {
  margin-bottom: 0; }

.block_coverage {
  text-align: center;
  border-top: 5px solid #ddd;
  border-bottom: 5px solid #ddd;
  background-color: #fff; }
  .block_coverage .row {
    justify-content: center; }
  @media (min-width: 992px) {
    .block_coverage .wrap {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; } }
  @media (min-width: 1200px) {
    .block_coverage .wrap {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; } }

.site_info {
  background-color: #227dc1;
  background: #166ead;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #166ead;
  color: #fff; }
  .site_info a {
    color: #fff; }
  .site_info .phone {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 800;
    color: #fff; }
  .site_info .list > li:not(:last-child) {
    margin-bottom: 4px; }
  @media (min-width: 576px) {
    .site_info .row {
      align-items: center; }
    .site_info .wrap {
      flex: 0 0 50%;
      max-width: 50%; }
    .site_info .info_copyright {
      text-align: right; }
    .site_info .list {
      margin-bottom: 0; } }

.front_jumbo {
  padding: 0;
  border-bottom: 5px solid #fff; }

.jumbo_marketing {
  padding: 0; }
  .jumbo_marketing .container {
    padding: 0; }
  .jumbo_marketing .row {
    margin: 0; }

.slider_jumbo .slide .wrap {
  padding: 0; }

@media (min-width: 576px) {
  .slider_jumbo .slide {
    background-size: cover;
    background-position: top center; }
    .slider_jumbo .slide .wrap {
      background-size: cover;
      background-position: top center; }
  .slider_jumbo .slide-placeholder {
    opacity: 0; }
  .slider_jumbo .slide_1 {
    background-image: url("/_/images/slides/jumbo/1-bg.jpg"); }
    .slider_jumbo .slide_1 .wrap {
      background-image: url("/_/images/slides/jumbo/1-lg.jpg"); }
  .slider_jumbo .slide_2 {
    background-image: url("/_/images/slides/jumbo/2-bg.jpg"); }
    .slider_jumbo .slide_2 .wrap {
      background-image: url("/_/images/slides/jumbo/2-lg.jpg"); }
  .slider_jumbo .slide_3 {
    background-image: url("/_/images/slides/jumbo/3-bg.jpg"); }
    .slider_jumbo .slide_3 .wrap {
      background-image: url("/_/images/slides/jumbo/3-lg.jpg"); }
  .slider_jumbo .slide_4 {
    background-image: url("/_/images/slides/jumbo/4-bg.jpg"); }
    .slider_jumbo .slide_4 .wrap {
      background-image: url("/_/images/slides/jumbo/4-lg.jpg"); } }

@media (min-width: 992px) {
  .slider_jumbo .slide .wrap {
    position: relative; } }

.block.front_environment {
  font-weight: 600;
  text-align: center;
  border-bottom: 5px solid #fff;
  background-color: #227dc1;
  background: #166ead;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #166ead;
  color: #fff; }
  .block.front_environment p.lead {
    margin: 0 0 8px; }
  .block.front_environment ul.list {
    margin: 0 0 8px; }
    .block.front_environment ul.list > li:not(:last-child) {
      margin: 0 15px 0 0; }
  .block.front_environment .btn {
    border: 2px solid #fff; }
  @media (min-width: 768px) {
    .block.front_environment ul.list {
      margin-bottom: 0; }
    .block.front_environment .environment_text {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
      padding-right: 0;
      text-align: left; }
    .block.front_environment .environment_conversion {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .block.front_environment p.lead {
      white-space: nowrap; }
    .block.front_environment .environment_conversion {
      margin-top: 40px; } }
  @media (min-width: 992px) {
    .block.front_environment .row {
      align-items: center; }
    .block.front_environment .environment_conversion .btn {
      font-size: 17px;
      font-size: 1.0625rem;
      padding: 15px 20px; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .block.front_environment ul.list {
      max-width: 600px; } }

.front_services {
  text-align: center; }
  @media (min-width: 768px) and (max-width: 991px) {
    .front_services .service_wrapper {
      display: flex;
      flex-wrap: wrap;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center; }
      .front_services .service_wrapper h3 {
        min-width: 180px;
        margin: 0; }
      .front_services .service_wrapper > div {
        text-align: left; } }
  @media (min-width: 768px) {
    .front_services .row {
      justify-content: center; } }
  @media (min-width: 992px) {
    .front_services .services_item {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }

.services_item {
  padding: 10px;
  line-height: 1.4em; }
  .services_item h3 {
    margin-bottom: 10px;
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 800;
    color: #227dc1; }
    .services_item h3 strong {
      display: block;
      margin-bottom: 10px; }

.service_wrapper {
  display: block;
  position: relative;
  padding: 25px 45px 25px 15px;
  border-radius: 3px;
  border: 1px solid #ddd;
  background-color: #fff;
  color: #343a40;
  text-decoration: none; }
  .service_wrapper > *:first-child {
    margin-top: 0; }
  .service_wrapper > *:last-child {
    margin-bottom: 0; }
  .service_wrapper p {
    margin-bottom: 0; }
  .service_wrapper .side_more {
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -ms-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms; }
  .service_wrapper:hover {
    color: #343a40; }
    .service_wrapper:hover .side_more {
      -webkit-transition: all 300ms;
      -moz-transition: all 300ms;
      -ms-transition: all 300ms;
      -o-transition: all 300ms;
      transition: all 300ms;
      background-color: #227dc1; }
      .service_wrapper:hover .side_more .icon {
        fill: #fff; }

.side_more {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 30px;
  border-radius: 0 3px 3px 0;
  background-color: #e5e5e5; }
  .side_more .icon {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    fill: #343a40; }

@media (min-width: 992px) {
  .front_welcome .row {
    justify-content: center; }
  .front_welcome .wrap {
    flex: 0 0 75%;
    max-width: 75%; } }

@media (min-width: 1200px) {
  .front_welcome .welcome-media {
    margin-top: -70px !important; } }

.welcome_title {
  /* text-align: center;
		@include media-breakpoint-up(lg) { text-align: left; } */ }

.welcome-img {
  clear: both;
  float: right;
  margin: 0 10px 20px 20px;
  width: 154px; }
  @media (min-width: 768px) {
    .welcome-img {
      padding: 0 20px;
      width: 209px; } }

.welcome-media {
  float: right;
  clear: both;
  margin: 0 10px 20px 20px; }

.list-welcome {
  display: inline-block;
  margin-top: 10px;
  padding: 35px 0;
  font-size: 17px;
  font-size: 1.0625rem;
  font-weight: 600;
  line-height: 1em;
  letter-spacing: 0.15em;
  text-align: center;
  background: url("/_/images/layout/home-bg.png") center center no-repeat;
  background-size: contain;
  color: #227dc1; }
  .list-welcome li {
    margin-bottom: 0 !important; }
  .list-welcome strong {
    font-size: 21px;
    font-size: 1.3125rem;
    font-weight: 800;
    letter-spacing: -0.0475em;
    color: #77bb43; }
  .list-welcome li:first-child {
    margin-left: 3em; }
  .list-welcome li:last-child {
    margin-left: -1em; }
  @media (min-width: 768px) {
    .list-welcome {
      font-size: 20px;
      font-size: 1.25rem; }
      .list-welcome strong {
        font-size: 27px;
        font-size: 1.6875rem; } }

.front_reviews {
  text-align: center;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
  background-color: rgba(0, 0, 0, 0.1); }
  .front_reviews .review {
    padding: 20px 15px;
    border-radius: 6px;
    border: 3px solid #f90;
    background-color: #fff; }
  .front_reviews .gmb-button-review {
    margin: 0 auto 15px; }
  .front_reviews .media_bbb {
    margin-top: 15px; }
  @media (min-width: 768px) {
    .front_reviews .gmb-buttton-review {
      margin-bottom: 0; }
    .front_reviews .review_media {
      flex: 0 0 50%;
      max-width: 50%; }
    .front_reviews .media_bbb {
      margin-top: 0; } }
  @media (max-width: 1199px) {
    .front_reviews .reviews_text {
      margin-bottom: 20px; } }
  @media (min-width: 992px) {
    .front_reviews .row {
      justify-content: center; }
    .front_reviews .reviews_text {
      flex: 0 0 75%;
      max-width: 75%; } }
  @media (min-width: 1200px) {
    .front_reviews .row {
      align-items: center; }
    .front_reviews .reviews_text {
      -webkit-box-flex: 0;
      flex: 0 0 60%;
      max-width: 60%;
      order: 2;
      padding: 0; }
    .front_reviews .review_media {
      -webkit-box-flex: 0;
      flex: 0 0 20%;
      max-width: 20%;
      padding: 0; }
      .front_reviews .review_media > a {
        -webkit-transform: scale(0.85);
        -moz-transform: scale(0.85);
        -o-transform: scale(0.85);
        transform: scale(0.85);
        margin: 0 -18px 0 0;
        line-height: 1em; }
        .front_reviews .review_media > a.gmb-button-review {
          margin: 0 0 0 -18px; }
    .front_reviews .media_google {
      order: 1;
      text-align: left; }
    .front_reviews .media_bbb {
      order: 3;
      text-align: right; } }

.review_media a:not(.gmb-button-review) {
  display: block; }

@media (min-width: 768px) {
  .front_visit .row {
    justify-content: center; }
  .front_visit .visit_text {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .front_visit .visit_media {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; } }

.visit-map {
  border: 5px solid #fff;
  background-image: url("/_/images/maps/map-sm.webp");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  @media (min-width: 992px) {
    .visit-map {
      background-image: url("/_/images/maps/map-lg.webp"); } }

.visit_text_wrapper {
  margin-top: 20px; }
  @media (min-width: 576px) {
    .visit_text_wrapper {
      display: flex;
      flex-wrap: wrap;
      flex-wrap: nowrap;
      justify-content: space-between; }
      .visit_text_wrapper > div {
        width: 48.5%; } }
  @media (min-width: 768px) {
    .visit_text_wrapper {
      position: relative;
      max-width: 570px;
      margin: -75px auto 0;
      padding: 20px 20px 0;
      background-image: linear-gradient(to bottom, #fff 25%, transparent 90%);
      border: 1px solid #ddd;
      border-bottom: none;
      box-shadow: 0 -3px 6px -3px rgba(0, 0, 0, 0.35); }
      .visit_text_wrapper::before, .visit_text_wrapper::after {
        content: "";
        position: absolute;
        top: 0;
        width: 1px;
        height: 80%;
        background-image: linear-gradient(to bottom, #ddd 60%, transparent); }
      .visit_text_wrapper::before {
        left: 0; }
      .visit_text_wrapper::after {
        right: 0; } }
  @media (min-width: 1200px) {
    .visit_text_wrapper {
      max-width: 80%; } }

.visit-subtitle {
  margin-top: 0; }

.hidden {
  display: none !important; }

@media (max-width: 575px) {
  .hidden--xs {
    display: none !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .hidden--sm {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden--md {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden--lg {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden--xl {
    display: none !important; } }

[class*="visible-"] {
  display: none !important; }

@media (max-width: 575px) {
  .visible--xs {
    display: block !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .visible--sm {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible--md {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible--lg {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible--xl {
    display: block !important; } }

.db {
  display: block !important; }

.mt0 {
  margin-top: 0 !important; }

.mt5 {
  margin-top: 5px !important; }

.mt10 {
  margin-top: 10px !important; }

.mt15 {
  margin-top: 15px !important; }

.mt20 {
  margin-top: 20px !important; }

.mt30 {
  margin-top: 30px !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mb5 {
  margin-bottom: 5px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.pt0 {
  padding-top: 0 !important; }

.pt10 {
  padding-top: 10px !important; }

.pt15 {
  padding-top: 15px !important; }

.pt20 {
  padding-top: 20px !important; }

.pt30 {
  padding-top: 30px !important; }

.pb0 {
  padding-bottom: 0 !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pb15 {
  padding-bottom: 15px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pb30 {
  padding-bottom: 30px !important; }

.no-transform {
  transform: none !important; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.ff--serif {
  font-family: "Times New Roman", Georgia, Times, serif !important; }

.ff--sans {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important; }

.txt--lg {
  font-size: 115% !important; }

.txt--md {
  font-size: inherit !important; }

.txt--sm {
  font-size: 85% !important; }

.bw0 {
  font-weight: 400 !important; }

.bw1 {
  font-weight: 600 !important; }

.bw2 {
  font-weight: 800 !important; }

.hw0 {
  font-weight: 400 !important; }

.hw1 {
  font-weight: 600 !important; }

.hw2 {
  font-weight: 800 !important; }

.ls--sm {
  letter-spacing: -0.0375em; }

.ls--rg {
  letter-spacing: 0; }

.ls--lg {
  letter-spacing: 0.275em; }

.lh1 {
  line-height: 1.1em !important; }

.lh125 {
  line-height: 1.25em !important; }

.txt--left {
  text-align: left !important; }

.txt--right {
  text-align: right !important; }

.txt--center {
  text-align: center !important; }

.txt--up {
  text-transform: uppercase !important; }

.txt--cap {
  text-transform: capitalize !important; }

.color--text {
  color: #343a40 !important; }

.color--white {
  color: #fff !important; }

.color--primary {
  color: #77bb43 !important; }

.color--secondary {
  color: #227dc1 !important; }

.color--highlight {
  color: #f5841f !important; }

.color--accent {
  color: #cc00ff !important; }

.color--link {
  color: #227dc1 !important; }

.color--review {
  color: #f90 !important; }

.color--success {
  color: #28a745 !important; }

.color--danger {
  color: #dc3545 !important; }

.color--warning {
  color: #ffc107 !important; }

.color--info {
  color: #0182C8 !important; }

.bg--text {
  background-color: #343a40 !important; }

.bg--white {
  background-color: #fff !important; }

.bg--primary {
  background-color: #77bb43 !important; }

.bg--secondary {
  background-color: #227dc1 !important; }

.bg--highlight {
  background-color: #f5841f !important; }

.bg--link {
  background-color: #227dc1 !important; }

.bg--review {
  background-color: #f90 !important; }

.bg--success {
  background-color: #28a745 !important; }

.bg--danger {
  background-color: #dc3545 !important; }

.bg--warning {
  background-color: #ffc107 !important; }

.bg--info {
  background-color: #0182C8 !important; }

.bg--transparent {
  background-color: transparent !important; }

.bg--body {
  background-color: #f5f5f5 !important; }

.gdfancybg--text {
  background: #343a40;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #343a40; }

.gdfancybg--white {
  background: #fff;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #fff; }

.gdfancybg--primary {
  background: #77bb43;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #77bb43; }

.gdfancybg--secondary {
  background: #227dc1;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #227dc1; }

.gdfancybg--highlight {
  background: #f5841f;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #f5841f; }

.gdfancybg--review {
  background: #f90;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #f90; }

.gdfancybg--success {
  background: #28a745;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #28a745; }

.gdfancybg--danger {
  background: #dc3545;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #dc3545; }

.gdfancybg--warning {
  background: #ffc107;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #ffc107; }

.gdfancybg--info {
  background: #0182C8;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #0182C8; }
