.block {
	//border: 1px solid red;
	padding: 30px 15px;

	.wrap > *:first-child { margin-top: 0; }
	.wrap > *:last-child { margin-bottom: 0; }

}

.block_front {}

	.block_title {
		margin-bottom: 15px;
	}

		.block-title {
			margin: 0;
		}

	.block_media {}

	.block_text {}

	.block_more {}