.coverage {
	
	line-height: 1.275em;

	> *:last-child { margin-bottom: 0; }

	p { margin-bottom: 8px; }

}


	.coverage_title {
		margin-top: 0;
		margin-bottom: 8px;
		@include font-size(18);
		font-weight: $body-weight-bolder;
	}

	.coverage_county {
		@include font-size(16);
		font-weight: $body-weight-bold;
	}

	.coverage_city {
		@include font-size(16);
	}