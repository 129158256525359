.menu_main {
	line-height: 0;
}

	.menu-main > .menu-item {

		z-index: 7;
		position: relative;
		display: inline-block;
		padding: 0 4px;
		@include font-size(15);
		font-weight: $body-weight-bold;

		&.active > .menu-link {
			border: none !important;
			background-color: transparent !important;
			//background: $body-bg url("/_/images/layout/body-bg.png");
			color: theme(primary, base) !important;
			b { font-weight: $body-weight-bolder; }
		}

		&.open > .menu-link {
			border-color: #ddd !important;
			background-color: $white !important;
			color: theme(primary, base);
			.caret { fill: theme(primary, base); }
		}

		> .menu-link {
			padding: 10px 15px;
			border-radius: 3px;
			border: 1px solid transparent;
			background-color: $color-secondary;
			color: $white;
			.caret { fill: $white; }
			&:hover {
				background-color: $color-primary;
			}
		}

		@include media-breakpoint-only(lg) {}

	}

	.menu-main .menu-link {
		padding: 10px;
		color: $color-text;
	}

	.menu-main .dropdown-menu {
		z-index: 0;
		margin-top: 5px;
		border-radius: 3px;
		border: 1px solid #ddd;
		background-color: $white;
  		box-shadow: 0 15px 10px -15px rgba(0, 0, 0, 0.6);

		.menu-item {

			&.active .menu-link {
				color: theme(primary, base);
				.caret-push { fill: theme(primary, base); }
			}

			&:not(:last-child) { border-bottom: 1px dotted #bbb; }

		}

		.menu-link {
			&:hover {
				color: $color-secondary;
				.caret-push { fill: $color-secondary; }
			}
		}

	}

body.service_page {
	.menu-main > .item_page_services {
		&.open > .menu-link { border-bottom: none; }
		> .menu-link {
			border-radius: 0;
			border-bottom: 1px solid theme(primary, base);
			color: theme(primary, base);
			.caret { fill: theme(primary, base); }
			&:hover { border-radius: 3px; }
		}
	}
}

